/* Container for each question's choices */
.choice-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  /* Style for each choice button */
  .choice-button {
    flex: 1;
  }