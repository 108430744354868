.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.app-container {
  border: 10px solid red; /* Red border */
  margin: 0; /* No margin */
  padding: 0; /* No padding */
  height: 100vh; /* Full viewport height */
  box-sizing: border-box; /* Include the border in the total width/height */
  position: relative;
}

.red-dot {
  width: 16px; /* Red dot size */
  height: 16px;
  background-color: red;
  border-radius: 10%; /* This makes the dot round */
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
}

.live-text {
  position: absolute;
  top: 6px;
  left: 40px; /* Positioned next to the red dot */
  color: red;
  font-weight: bold;
  font-size: 20px; /* Increase the font size */
}
